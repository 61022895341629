import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FORM_FIELDS } from '../../../constants';
import { Col, Row } from '../../landing.common';
import { PeriodAndAmountView } from './period.amount.fields.view';
import { Button } from '@sravni/react-design-system';
import { PersonalDataText } from '../../form.common/components';
import { useIsMobile } from '@sravni/react-utils';
import styles from '../styles.module.scss';
import { RegionField } from '../../form.common/fields';

export const DefaultView: FC = () => {
    const isMobile = useIsMobile();
    const form = useFormContext();
    const { control } = form;

    return (
        <>
            <Row gap="12">
                <Col>
                    <Controller
                        control={control}
                        name={FORM_FIELDS.REGION}
                        render={({ field }) => <RegionField field={field} form={form} />}
                    />
                </Col>
            </Row>
            {isMobile && (
                <Row>
                    <PeriodAndAmountView />
                </Row>
            )}
            <Row className={styles['submit-row']}>
                <Button type="submit" variant="primary" color="gray" size={52} className={styles['submit-btn']}>
                    Заполнить анкету
                </Button>
                <PersonalDataText className={styles['personal']} />
            </Row>
        </>
    );
};
