import { FC, memo } from 'react';
import { Typography } from '@sravni/react-design-system';
import { format } from 'date-fns';
import { Container } from '../../../landing.common';
import { format as formatAmount } from '../../../../helpers';
import styles from './styles.module.scss';

const { Heading, Text } = Typography;

export const Hero: FC = memo(function HeroComponent() {
    const year = new Date().getFullYear();
    const today = format(new Date(), 'dd.MM.yyyy');
    const offersCount = 285;
    const fullAmount = formatAmount(600_000_000);
    return (
        <Container className={styles.hero}>
            <div className={styles.hero__text}>
                <Heading level={1} className={styles.header_text}>
                    Подбор кредита
                </Heading>
                <Text size={14} className={styles.description_text}>
                    Сравните условия в разных банках и решите, где&nbsp;лучше&nbsp;всего&nbsp;взять&nbsp;кредит в {year}
                    &nbsp;году. На&nbsp;
                    {today}&nbsp;вам доступно&nbsp;{offersCount}&nbsp;предложений суммой до&nbsp;{fullAmount}
                    &nbsp;рублей!
                </Text>
            </div>
            <div className={styles.hero__image}>
                <img src={require('../../assests/hero/hero.png')} alt="Credit img" />
            </div>
        </Container>
    );
});
