import { Heading, Text } from '@sravni/react-design-system/lib/Typography';
import { Accordion, Space } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import { Container } from '../../landing.common';

import styles from '../landing.module.scss';

export const FAQSection = () => {
    const isMobile = useIsMobile();

    return (
        <Container className={styles.container}>
            <Space className={styles.faqSection} size={isMobile ? 16 : 32} direction="vertical">
                <Space size={8} direction="vertical">
                    <Heading level={3}>FAQ</Heading>
                    <Text className={styles.textLight} size={16}>
                        Отвечаем на самые популярные вопросы
                    </Text>
                </Space>
                <Space size={isMobile ? 16 : 40} align="start" direction={isMobile ? 'vertical' : 'horizontal'}>
                    <img className={styles.img} alt="faq" src={require('../assets/family.jpg')} />
                    <Accordion
                        defaultIndex={[0]}
                        className={styles.accordion}
                        background={isMobile ? 'white' : undefined}>
                        <Accordion.Item title="Как получить кредит через Сравни?">
                            Заполните короткую анкету, узнайте решение банка онлайн на Сравни и получите деньги в
                            отделении. Подскажем, какие документы для этого необходимы и где удобнее всего получить
                            кредит
                        </Accordion.Item>
                        <Accordion.Item title="Это быстро?">
                            Да. Заполните небольшую анкету. Запросим минимум данных, которые нужны банкам для принятия
                            решения по заявке - в среднем это занимает не более 5 минут
                        </Accordion.Item>
                        <Accordion.Item title="Мне точно одобрят?">
                            Покажем предложения, подходящие под ваш кредитный рейтинг, поэтому шанс одобрения кредита
                            очень высок
                        </Accordion.Item>
                    </Accordion>
                </Space>
            </Space>
        </Container>
    );
};
