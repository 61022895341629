import { FC, useCallback } from 'react';
import { Button } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';
import styles from './styles.module.scss';

const APP_LINK = 'https://m.sravni.ru/YbYnHpCc';

export const GoToAppButton: FC = () => {
    const isMobile = useIsMobile();
    const onClick = useCallback(() => {
        window.open(APP_LINK, '_blank');
    }, []);

    if (!isMobile) {
        return null;
    }

    return (
        <div className={styles.app_button}>
            <Button variant="secondary" size={52} className={styles.app_button__btn} color="violet" onClick={onClick}>
                Открыть в приложении
            </Button>
        </div>
    );
};
