import { FORM_FIELDS } from '../../constants';

export const getLandingFormData = (formData: FE.FormData) => {
    return {
        [FORM_FIELDS.PURPOSE]: formData[FORM_FIELDS.PURPOSE],
        [FORM_FIELDS.AMOUNT]: formData[FORM_FIELDS.AMOUNT],
        [FORM_FIELDS.REGION_ID]: formData[FORM_FIELDS.REGION_ID],
        [FORM_FIELDS.REGION]: formData[FORM_FIELDS.REGION],
        [FORM_FIELDS.KLADR_ID]: formData[FORM_FIELDS.KLADR_ID],
        [FORM_FIELDS.REGION_LOCATION_ROUTE]: formData[FORM_FIELDS.REGION_LOCATION_ROUTE],
        [FORM_FIELDS.PERIOD]: formData[FORM_FIELDS.PERIOD],
        [FORM_FIELDS.AGREEMENT]: formData[FORM_FIELDS.AGREEMENT],
    };
};
