import { Heading, Text } from '@sravni/react-design-system/lib/Typography';
import { Space } from '@sravni/react-design-system';

import styles from './SectionTitle.module.scss';
import { useIsMobile } from '@sravni/react-utils';
import cn from 'classnames';

interface IProps {
    title: string;
    subtitle?: string;
    className?: string;
}

export const SectionTitle = ({ title, subtitle, className }: IProps) => {
    const isMobile = useIsMobile();

    return (
        <Space className={cn(styles.wrapper, className)} direction="vertical" size={isMobile ? 12 : 16}>
            <Heading className={cn(styles.text, styles.text_title)} level={2}>
                {title}
            </Heading>
            {subtitle && (
                <Text className={styles.text} size={isMobile ? 16 : 20}>
                    {subtitle}
                </Text>
            )}
        </Space>
    );
};
