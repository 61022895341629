import { Button, Card, Icon, Space } from '@sravni/react-design-system';
import { Heading, Text } from '@sravni/react-design-system/lib/Typography';
import { ArrowRight } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';
import cn from 'classnames';

import { Container } from '../../landing.common';
import { SectionTitle } from '../components';

import styles from '../landing.module.scss';

interface IProps {
    onClick: () => void;
}

export const SecuritySection = ({ onClick }: IProps) => {
    const isMobile = useIsMobile();

    return (
        <Container className={styles.container}>
            <Space size={24} direction="vertical">
                <SectionTitle
                    title="Безопасно, всё под контролем"
                    subtitle="Все данные хранятся и передаются в зашифрованном виде"
                />
                <Space size={isMobile ? 8 : 24} direction="vertical">
                    <Space size={isMobile ? 8 : 24} direction={isMobile ? 'vertical' : 'horizontal'}>
                        <Card className={cn(styles.card, styles.card_personalData)} color="dark">
                            <Space size={isMobile ? 8 : 24} direction="vertical">
                                <Heading level={3}>
                                    Личные данные <br /> надёжно защищены
                                </Heading>
                                <Text size={isMobile ? 14 : 16}>
                                    Мы бережно относимся к личным данным и передаём их только в те банки, которые вы
                                    выберете&nbsp;сами
                                </Text>
                            </Space>
                        </Card>
                        <Space size={isMobile ? 8 : 24} direction="vertical">
                            <Card className={cn(styles.card, styles.card_cbrf)} color="dark">
                                <Space size={isMobile ? 8 : 16} direction="vertical">
                                    <Heading level={4}>Никакой комиссии</Heading>
                                    <Text size={isMobile ? 14 : 16}>
                                        Сервис подбора — абсолютно бесплатный, а условия кредитов — такие же, как на
                                        сайтах банков
                                    </Text>
                                </Space>
                            </Card>
                            <Card className={cn(styles.card, styles.card_highOport)} color="dark">
                                <Space
                                    size={16}
                                    direction="vertical"
                                    align="start"
                                    className={styles.card_highOport_space}>
                                    <Heading level={4}>
                                        Высокая вероятность <br /> одобрения
                                    </Heading>
                                    <Text size={isMobile ? 14 : 16}>
                                        Проверим ваш кредитный рейтинг и покажем только те банки, которые вероятнее
                                        всего одобрят вам кредит
                                    </Text>
                                </Space>
                            </Card>
                        </Space>
                    </Space>
                    <Card className={cn(styles.card, styles.card_rating)} color="dark">
                        <Space
                            size={isMobile ? 8 : 16}
                            direction="vertical"
                            align="start"
                            className={styles.card_rating_space}>
                            <Heading level={4}>Рекомендуем узнать свой кредитный рейтинг</Heading>
                            <Text size={isMobile ? 14 : 16}>
                                Покажем вашу кредитную историю и&nbsp;максимальную сумму, которую могут
                                одобрить&nbsp;банки
                            </Text>
                            <Button
                                size={36}
                                className={styles.linkButton}
                                variant="text"
                                color="blue"
                                onClick={onClick}>
                                <Space align="center" size={4}>
                                    Узнать свой рейтинг <Icon icon={<ArrowRight />} />
                                </Space>
                            </Button>
                        </Space>
                    </Card>
                </Space>
            </Space>
        </Container>
    );
};
