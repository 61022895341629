import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';
import { useIsMobile, useIsTablet } from '@sravni/react-utils';
import { useUser } from '../../store/reducers/user';
import {
    AMOUNT_LIMITS,
    CREDIT_SELECTION_ABC_EXPERIMENT_IDS,
    FORM_FIELDS,
    FORM_FIELDS_PHONES,
    INT_CROSS_KEY,
    PAGE,
    QUERY_PARAMS,
    UTM_VALUES,
} from '../../constants';
import { SelectionApp } from '../../types/app';
import { getLocationByRoute } from '../../services/location/location.service';
import { getOrganizationByAlias } from '../../services/organizations';
import { loadReviews } from '../../services/reviews';
import {
    convertEmployerType,
    formatPhonesImmutable,
    getGlobalRegionFormFields,
    parseIntCrossFromCookieOrQuery,
    removeUndefined,
    samePhones,
    scrollToElementById,
} from '../../helpers';
import { getGlobalRegionForForm, getIp, logProfileAndIdentityPhonesError } from '../../server/helpers';
import { getCommonServerSideProps } from '../../helpers/serverside.props';

import { oldPurposeToNew, PERIOD_SOURCE, PURPOSE_SOURCE_VALUES } from '../../constants/sources';
import { FormFallbackLoader } from '../../components/landing.common';
import { setSelection } from '../../store/reducers/selection';
import LandingAccurateOffers from '../../components/landing.accurate.offers';
import { queue } from '../../helpers/queue';
import { LandingFormModule, ReactLottieModule } from '../../helpers/dynamic.modules';
import { OsagoPopup } from '../../components/landing/popup';
import { loadProfileData } from '../../services/profile.graphql';
import { getFreshVerificationSmsCode } from '../../helpers/server/fresh.verification.code';
import { getAuthOpenId } from '../../services/openid';
import { toggleSeoBlocks } from '../../store/reducers/metadata';
import { LandingCreditOffers } from '../../components/landing.credit.offers';
import { useExperimentGroups } from '../../components/hooks/experiments';

const Form = dynamic(LandingFormModule, {
    ssr: false,
    loading: () => <FormFallbackLoader />,
});

export interface Props {
    memoryData: FE.FormData;
    isScrollToForm: boolean;
    isFromCreditCardPage: boolean;
    isFromCrmCommunication: boolean;
    cookies: Record<string, string>;
    skipLanding?: boolean;
}

const ID_FOR_SCROLLING_TO_FORM = 'markerForScrollingToCreditOnlineV3';

const Landing = (props: Props) => {
    const { isFromCreditCardPage, isFromCrmCommunication, memoryData, cookies, skipLanding } = props;
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const account = useUser();
    const dispatch = useDispatch();
    const [autoRun] = useState(Boolean(account));
    const [withLanding, setLanding] = useState(!skipLanding);
    const userId = account?.sub;
    const [landingData, setLandingData] = useState<FE.FormData>(
        removeUndefined({
            [FORM_FIELDS.AMOUNT]: memoryData[FORM_FIELDS.AMOUNT],
            [FORM_FIELDS.PERIOD]: memoryData[FORM_FIELDS.PERIOD],
            [FORM_FIELDS.PURPOSE]: memoryData[FORM_FIELDS.PURPOSE],
        }),
    );

    const [isShowNewLanding] = useExperimentGroups({
        name: CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_MAIN_LANDING,
        value: ['1', '2'],
    });

    const hideLanding = () => setLanding(false);

    useEffect(() => {
        if (props.isScrollToForm) {
            setTimeout(() => scrollToElementById(ID_FOR_SCROLLING_TO_FORM), 500);
        }
        /** Позволяет загрузить динамические модули ДО первого вызова (preload) */
        queue.push(ReactLottieModule);
        queue.push(LandingFormModule);
    }, []);

    useEffect(() => {
        dispatch(setSelection({ miniFooter: !withLanding }));
        dispatch(toggleSeoBlocks({ show: withLanding }));
    }, [withLanding]);

    return (
        <>
            {isFromCrmCommunication && <OsagoPopup />}
            {withLanding ? (
                isShowNewLanding ? (
                    <LandingCreditOffers hideLanding={hideLanding} />
                ) : (
                    <LandingAccurateOffers
                        hideLanding={hideLanding}
                        landingData={landingData}
                        setLandingData={setLandingData}
                    />
                )
            ) : (
                <Form
                    cookies={cookies}
                    headline={isFromCreditCardPage ? 'Заявка на кредитную карту' : undefined}
                    memoryData={memoryData}
                    setShowLanding={setLanding}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    userId={userId}
                    autoRun={autoRun}
                    formData={landingData}
                />
            )}
        </>
    );
};

export const getServerSideProps = async (ctx: SelectionApp.PageContext) => {
    const {
        [QUERY_PARAMS.ANCHOR_TO_FORM]: anchorToForm,
        [QUERY_PARAMS.AMOUNT]: amount,
        [QUERY_PARAMS.PURPOSE]: purpose,
        [QUERY_PARAMS.PERIOD]: period,
        [QUERY_PARAMS.LOCATION]: location,
        [QUERY_PARAMS.PARTNER_KEY]: partnerKey,
        [QUERY_PARAMS.PARTNER_OFFER_ID]: partnerOfferId,
        [QUERY_PARAMS.SRAVNI_AFF_SUB3]: sravniAffSub3,
        [QUERY_PARAMS.UTM_MEDIUM]: utmMedium,
        [QUERY_PARAMS.UTM_SOURCE]: utmSource,
        [QUERY_PARAMS.UTM_CAMPAIGN]: utmCampaign,
        [QUERY_PARAMS.UTM_CONTENT]: utmContent,
        [QUERY_PARAMS.SKIP_LANDING]: skipLandingFromQuery,
    } = ctx.query || {};
    const { url: realPath = '', __USER__: user } = ctx.req;

    // TODO: Это должна быть отдельная страница.
    const bankLandingPageRegexp = /\/bank\/([\w|-]+)\/kredity\/onlajn-zayavka-na-kredit/;
    const bankMatch = realPath.match(bankLandingPageRegexp);
    const bankAlias = bankMatch?.[1];

    const [
        { props: commonPageProps },
        {
            data: { [FORM_FIELDS.PHONE]: profilePhone, ...profileData },
            profileMetaData,
        },
        bank,
        locationsByRoute,
    ] = await Promise.all([
        getCommonServerSideProps(PAGE.LANDING)(ctx),
        loadProfileData(ctx),
        bankAlias ? getOrganizationByAlias(bankAlias) : Promise.resolve(null),
        // Проверка что location не string[]
        typeof location === 'string' ? getLocationByRoute(location) : Promise.resolve(null),
    ]);

    const userIdentityPhone = commonPageProps.identityPhone;
    const userHasValidProfile = Boolean(
        commonPageProps.userId && samePhones(userIdentityPhone, profilePhone as string),
    );

    const { date: smsDate = null } = userHasValidProfile
        ? await getFreshVerificationSmsCode(getAuthOpenId(), String(commonPageProps.userId), getIp(ctx.req))
        : {};

    if (commonPageProps.userId && !userHasValidProfile) {
        const profile = {
            [FORM_FIELDS.PHONE]: profilePhone,
            ...profileData,
        };
        logProfileAndIdentityPhonesError(user, profile, commonPageProps);
    }

    const reviews =
        bank !== null || commonPageProps.initialState.metadata.showingSeoBlocks.reviews
            ? await loadReviews(bank ? bank.item.oldId || bank.item._id : undefined, {
                  OrderBy: 'highRateFirst',
                  UniqueOnly: true,
              })
            : undefined;
    const { pathname } = new URL(realPath || '', `http://${ctx?.req?.headers?.host}`);

    const intCrossData = parseIntCrossFromCookieOrQuery(decodeURI(commonPageProps.cookies[INT_CROSS_KEY]), ctx.query);

    const getIsScrollToForm = () => {
        const { tablet: isTablet, phone: isPhone } = commonPageProps.deviceInfo;

        if (isTablet && isPhone) {
            return false;
        }

        return typeof anchorToForm !== 'undefined';
    };
    const getIsFromCreditCardPage = () => {
        if (!sravniAffSub3) {
            return false;
        }

        return sravniAffSub3.includes('ip_category=creditCard');
    };
    const getSelectionDataPurpose = () => {
        if (
            typeof purpose === 'string' &&
            Object.values(PURPOSE_SOURCE_VALUES).includes(purpose as ValuesOf<typeof PURPOSE_SOURCE_VALUES>)
        ) {
            return oldPurposeToNew(purpose);
        }

        if (typeof partnerKey === 'string' && parseInt(partnerKey) === 1778) {
            return PURPOSE_SOURCE_VALUES.REFINANCE_CREDIT;
        }

        return profileData[FORM_FIELDS.PURPOSE]
            ? oldPurposeToNew(profileData[FORM_FIELDS.PURPOSE] as any)
            : PURPOSE_SOURCE_VALUES.MONEY;
    };
    const getSelectionAmount = () => {
        if (typeof amount === 'string' && !isNaN(parseInt(amount))) {
            return Math.max(Math.min(parseInt(amount), AMOUNT_LIMITS.MAX), AMOUNT_LIMITS.MIN);
        }

        return profileData[FORM_FIELDS.AMOUNT];
    };
    const getSelectionPeriod = () => {
        if (typeof period === 'string' && PERIOD_SOURCE.map(({ value }) => value).includes(period.toUpperCase())) {
            return period.toUpperCase();
        }

        return profileData[FORM_FIELDS.PERIOD];
    };

    const globalRegion =
        Array.isArray(locationsByRoute) && locationsByRoute.length > 0
            ? locationsByRoute[0]
            : await getGlobalRegionForForm({
                  currentLocation: commonPageProps.initialState.locations.currentLocation,
                  useCurrentLocation: commonPageProps.isLocationManuallySet,
                  defaultLocation: {
                      id: profileData[FORM_FIELDS.REGION_ID] as number | undefined,
                      name: profileData[FORM_FIELDS.REGION] as string | undefined,
                  },
              });

    const memoryData = {
        ...(userHasValidProfile ? profileData : {}),
        [FORM_FIELDS.PURPOSE]: getSelectionDataPurpose(),
        [FORM_FIELDS.AMOUNT]: getSelectionAmount() || 500000,
        [FORM_FIELDS.PERIOD]: getSelectionPeriod() || 'P5Y',
        [FORM_FIELDS.SMS_CODE_DATE]: smsDate,
        ...getGlobalRegionFormFields(globalRegion),
        [FORM_FIELDS.PHONE]: userIdentityPhone || '',
        ...(userHasValidProfile && profileData[FORM_FIELDS.EMPLOYER_TYPE]
            ? { [FORM_FIELDS.EMPLOYER_TYPE]: convertEmployerType(profileData[FORM_FIELDS.EMPLOYER_TYPE] as string) }
            : {}),
    };

    const getIsFromCrmCommunication = () => {
        return (
            utmCampaign === UTM_VALUES.CAMPAIGN &&
            utmSource === UTM_VALUES.SOURCE &&
            UTM_VALUES.MEDIUM.includes(utmMedium as string) &&
            utmContent === UTM_VALUES.CONTENT
        );
    };

    const { initialState } = commonPageProps;

    const skipLanding =
        skipLandingFromQuery ||
        memoryData[FORM_FIELDS.AGREEMENT] ||
        (!!partnerKey && !user && partnerOfferId === '1580');

    return removeUndefined({
        props: {
            ...commonPageProps,
            initialState: {
                ...initialState,
                user: smsDate ? initialState.user : {},
                locations: {
                    ...initialState.locations,
                    currentLocation: globalRegion || initialState.locations.currentLocation,
                },
                advData: {
                    crossData: intCrossData,
                },
                ...(bank ? { organization: bank } : {}),
                ...(reviews ? { reviews } : {}),
                ...(!skipLanding
                    ? {
                          formStore: {
                              ...initialState.formStore,
                              formData: {
                                  ...initialState.formStore.formData,
                                  ...memoryData,
                              },
                          },
                      }
                    : {}),
                ...(profileMetaData
                    ? {
                          formStore: {
                              ...initialState.formStore,
                              profileMetaData,
                          },
                      }
                    : {}),
            },
            memoryData: formatPhonesImmutable(memoryData, FORM_FIELDS_PHONES),
            isScrollToForm: getIsScrollToForm(),
            isFromCreditCardPage: getIsFromCreditCardPage(),
            isFromCrmCommunication: getIsFromCrmCommunication(),
            skipLanding,
        },
    });
};

export default Landing;
