import React, { FC, useMemo } from 'react';
import { amountTransform, Col, SelectInput, TextInput } from '../../landing.common';
import { Controller, useFormContext } from 'react-hook-form';
import { FORM_FIELDS, LABELS } from '../../../constants';
import { PERIOD_SOURCE } from '../../../constants/sources';

export const PeriodAndAmountView: FC = () => {
    const periodOptions = useMemo(() => PERIOD_SOURCE.map(({ name: label, value }) => ({ label, value })), []);
    const form = useFormContext();
    const {
        control,
        formState: { errors },
    } = form;

    return (
        <>
            <Col md-4 sm-6>
                <Controller
                    control={control}
                    name={FORM_FIELDS.AMOUNT}
                    render={({ field }) => (
                        <TextInput
                            {...field}
                            label={LABELS[FORM_FIELDS.AMOUNT]}
                            value={amountTransform.input(field)}
                            onChange={amountTransform.output(field)}
                            errorMessage={errors[FORM_FIELDS.AMOUNT]?.message}
                        />
                    )}
                />
            </Col>
            <Col md-4 sm-6>
                <Controller
                    control={control}
                    name={FORM_FIELDS.PERIOD}
                    render={({ field }) => (
                        <SelectInput
                            {...field}
                            withCrossIcon={false}
                            options={periodOptions}
                            label="Срок кредита"
                            errorMessage={errors[FORM_FIELDS.PERIOD]?.message}
                        />
                    )}
                />
            </Col>
        </>
    );
};
