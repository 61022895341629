import { FC, useCallback } from 'react';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { Spinner } from '@sravni/react-design-system';
import { Container } from '../../../landing.common';
import { isProduction } from '../../../../helpers';
import { AMOUNT_LIMITS, FORM_FIELDS } from '../../../../constants';
import styles from './styles.module.scss';

interface IProps {
    landingData: FE.FormData;
    setLandingData: (landingData: FE.FormData) => void;
}

const SelectionCalculator = dynamic(import('./calculator.client'), {
    ssr: false,
    loading: () => (
        <div className={styles.calculatorLoader}>
            <Spinner size={28} />
        </div>
    ),
});

export const Calculator: FC<IProps> = (props) => {
    const { landingData, setLandingData } = props;

    const onChange = useCallback(
        (field: string, value: any) => {
            setLandingData({
                [field]: value,
            });
        },
        [setLandingData],
    );

    return (
        <Container className={styles.calculator}>
            <SelectionCalculator
                baseUrl={getConfig().publicRuntimeConfig.webPath}
                enableAnalytics={isProduction ? true : 'logs'}
                defaultFormValues={{
                    amount: landingData[FORM_FIELDS.AMOUNT],
                    period: landingData[FORM_FIELDS.PERIOD],
                    purpose: landingData[FORM_FIELDS.PURPOSE],
                    rate: 31,
                }}
                onFieldChange={onChange}
                formParams={{
                    amount: {
                        min: AMOUNT_LIMITS.MIN,
                        max: AMOUNT_LIMITS.MAX,
                        step: 50_000,
                    },
                }}
            />
        </Container>
    );
};
