import { useIsMobile } from '@sravni/react-utils';
import { FC, useCallback } from 'react';
import { Space } from '@sravni/react-design-system';
import { useDispatch } from 'react-redux';
import { extendFormData } from '../../store/reducers/formStore';
import { useFormData } from '../../store/selectors/formStore';
import { useQueryData } from '../form/hooks/queryData';
import { FORM_FIELDS } from '../../constants';
import { Hero } from './components/hero';
import { Calculator } from './components/calculator';
import { Cards } from './components/cards';
import { LoansList } from './components/loans.list';
import { GoToAppButton } from './components/app.button';
import styles from './styles.module.scss';

interface IProps {
    hideLanding: () => void;
}

export const LandingCreditOffers: FC<IProps> = () => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const formData = useFormData();
    useQueryData(true, { [FORM_FIELDS.REGION_LOCATION_ROUTE]: 'location' });

    const setFormData = useCallback(
        (data: FE.FormData) => {
            dispatch(extendFormData(data));
        },
        [dispatch],
    );

    return (
        <Space className={styles.wrapper} size={isMobile ? 0 : 32} direction="vertical">
            <Space size={isMobile ? 16 : 24} direction="vertical">
                <Hero />
                <Calculator landingData={formData} setLandingData={setFormData} />
                <Cards />
                <LoansList landingData={formData} setLandingData={setFormData} />
            </Space>
            <GoToAppButton />
        </Space>
    );
};
