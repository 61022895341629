import { Space } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';
import { createContext, useCallback, useEffect } from 'react';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS } from '../../constants';
import { Categories, EventType } from '../../helpers/analytics';
import { useAnalytics } from '../hooks/analytics';
import { useExperimentGroups } from '../hooks/experiments';
import { useShowingSeoBlocks } from '../../store/reducers/metadata';
import { SCORING_URL } from './constants';
import { FAQSection, HowToSection, SecuritySection, TopSection } from './sections';
import styles from './landing.module.scss';

interface IProps {
    hideLanding: () => void;
    landingData: FE.FormData;
    setLandingData: (landingData: FE.FormData) => void;
}

export const LandingContext = createContext({ withNewForm: false });

export const Landing = ({ hideLanding, landingData, setLandingData }: IProps) => {
    const isMobile = useIsMobile();
    const { pushEvent } = useAnalytics();
    const { seoBlocks, breadcrumbs, prefooter } = useShowingSeoBlocks();
    const isShowSeoBlocks = seoBlocks || breadcrumbs || prefooter;

    const [isShowNewFormStep] = useExperimentGroups({
        name: CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_FOCUSED_FORM,
        value: '1',
    });

    useEffect(() => {
        pushEvent({
            event: EventType.mainEvent,
            eventCategory: Categories.Loan,
            eventAction: 'Показ лендинга',
            eventLabel: 'Стартовый лендинг подбора кредита',
        });
    }, []);

    const onClick = useCallback(() => {
        setLandingData(landingData);
        pushEvent({
            event: EventType.mainEvent,
            eventCategory: Categories.Loan,
            eventAction: 'Переход к анкете',
            eventLabel: 'Заполнить заявку|Зафиксировано',
        });
        hideLanding();
    }, [pushEvent, hideLanding, landingData, setLandingData]);

    const showScoringPage = useCallback(() => {
        pushEvent({
            event: EventType.mainEvent,
            eventCategory: Categories.Loan,
            eventAction: 'Переход в Кредитный рейтинг',
            eventLabel: 'Лендинг Подбор кредита',
        });
        window.open(SCORING_URL, '_blank');
    }, []);

    return (
        <LandingContext.Provider value={{ withNewForm: isShowNewFormStep }}>
            <Space className={styles.wrapper} size={isMobile ? 32 : 40} direction="vertical">
                <TopSection onClick={onClick} />
                <HowToSection />
                <SecuritySection onClick={showScoringPage} />
                {!isShowSeoBlocks && <FAQSection />}
            </Space>
        </LandingContext.Provider>
    );
};
