import { FC, memo } from 'react';
import { Card, Typography } from '@sravni/react-design-system';
import { Container } from '../../../landing.common';
import styles from './styles.module.scss';

const cardsData = [
    {
        imgUrl: require('../../assests/cards/card1.png'),
        text: 'Ставка и одобрение без\u00A0назойливых\u00A0звонков',
    },
    {
        imgUrl: require('../../assests/cards/card2.png'),
        text: 'Максимальная сумма, которую вам одобрят',
    },
    {
        imgUrl: require('../../assests/cards/card3.png'),
        text: (
            <>
                Предложения <br /> {'от\u00A0топовых\u00A0банков'}
            </>
        ),
    },
];

const { Heading } = Typography;

export const Cards: FC = memo(function CardsComponent() {
    return (
        <Container className={styles.cards_list}>
            {cardsData.map((item, i) => (
                <Card key={i} className={styles.card}>
                    {item.imgUrl && <img src={item.imgUrl} alt="" className={styles.card__img} />}
                    {item.text && (
                        <Heading level={5} className={styles.card__text}>
                            {item.text}
                        </Heading>
                    )}
                </Card>
            ))}
        </Container>
    );
});
