import { Badge, Button, Space } from '@sravni/react-design-system';
import cn from 'classnames';
import { useIsMobile } from '@sravni/react-utils';
import { Shield, Lock, Percent } from '@sravni/react-icons';
import { Heading, Text } from '@sravni/react-design-system/lib/Typography';
import { WithImage } from '../components';
import { Container } from '../../landing.common';
import { useContext } from 'react';
import { LandingContext } from '../landing';
import { LandingForm } from '../../form.landing';
import styles from '../landing.module.scss';

interface IProps {
    onClick: () => void;
}

export const TopSection = ({ onClick }: IProps) => {
    const isMobile = useIsMobile();
    const { withNewForm } = useContext(LandingContext);

    return (
        <Container className={cn(styles.container, styles.topSecContainer)}>
            <Space size={isMobile ? (withNewForm ? 16 : 12) : 0} direction="vertical">
                <div className={cn(styles.topSecSpace, { [styles.topSecSpace_heading]: withNewForm })}>
                    <Space
                        className={cn(styles.topSec, { [styles.topSec_withForm]: withNewForm })}
                        direction="vertical"
                        size={!isMobile ? 32 : 0}>
                        <Space className={styles.headingBlock} direction="vertical" size={16}>
                            <Heading level={1}>Подбор кредита</Heading>
                            <Space size={8} direction={isMobile ? 'vertical' : 'horizontal'}>
                                <Badge color="orange" text="решение через 5 минут" />
                            </Space>
                            <Text className={styles.textLight} size={isMobile ? 12 : 16}>
                                Подберём банки, которые готовы выдать кредит {!isMobile && <br />} на подходящих вам
                                условиях
                            </Text>
                        </Space>
                        {!isMobile && !withNewForm && (
                            <Space size={28} justify="start">
                                <Button
                                    className={styles.desktopBtn}
                                    variant="primary"
                                    block={isMobile}
                                    size={60}
                                    onClick={onClick}>
                                    Заполнить анкету
                                </Button>
                            </Space>
                        )}
                    </Space>
                    {isMobile && !withNewForm && (
                        <Button variant="primary" block={isMobile} size={60} onClick={onClick}>
                            Заполнить анкету
                        </Button>
                    )}
                </div>
                <Space size={24} direction="vertical">
                    {withNewForm && <LandingForm onSubmit={onClick} />}
                    <Space
                        size={12}
                        direction={isMobile ? 'vertical' : 'horizontal'}
                        className={cn(styles.topSecSpace, { [styles.topSecSpace_withForm]: withNewForm })}>
                        <WithImage icon={<Lock />} className={styles['topSec__info-item']}>
                            <Text size={12}>Мы проходим регулярный аудит информационной безопасности</Text>
                        </WithImage>

                        <WithImage icon={<Shield />} className={styles['topSec__info-item']}>
                            <Text size={12}>Все данные передаются {!isMobile && <br />} в зашифрованном виде</Text>
                        </WithImage>
                        <WithImage icon={<Percent />} className={styles['topSec__info-item']}>
                            <Text size={12}>Условия такие же, {!isMobile && <br />} как на сайтах банков</Text>
                        </WithImage>
                    </Space>
                </Space>
            </Space>
        </Container>
    );
};
