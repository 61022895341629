export const BANKS_LOGOS = [
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/alfa.svg',
        name: 'Alfa Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/t_bank.svg',
        name: 'Tinkoff Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/gazprom.svg',
        name: 'Gazprom Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/vtb.svg',
        name: 'VTB Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/bank_psb.svg',
        name: 'PSB Bank',
    },

    {
        url: '/resources/selection/credit-cards-landing/banks-logos/mts.svg',
        name: 'Mts Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/sovkom.svg',
        name: 'Sovkom Bank',
    },
    {
        url: '/resources/selection/credit-cards-landing/banks-logos/renessans.svg',
        name: 'Renessans Bank',
    },
];
