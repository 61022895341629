import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from '@sravni/react-design-system';
import cn from 'classnames';
import { useTheme } from '@sravni/react-utils';
import getConfig from 'next/config';
import { useIframeResizer } from '../../../hooks/iframe.resizer';
import { CREDIT_SELECTION_ABC_EXPERIMENT_IDS, FORM_FIELDS } from '../../../../constants';
import { useCurrentLocation } from '../../../../store/reducers/locations';
import { useExperimentGroups } from '../../../hooks/experiments';
import { isProduction } from '../../../../helpers';
import styles from './styles.module.scss';

const { webPath } = getConfig().publicRuntimeConfig;
const LOANS_LIST_URL = `${isProduction ? webPath : 'https://master.sravni.ru.dev.lan'}/kredity/podbor-i-frame/`;

type IframeEvent = Event & {
    data?: {
        eventType?: string;
        data?: Record<string, unknown>;
    };
};

interface IProps {
    landingData: FE.FormData;
    setLandingData: (landingData: FE.FormData) => void;
}

interface FrameProps {
    url: string;
    onScriptLoaded: () => void;
    isContentLoading: boolean;
}

const PodborFrame: FC<FrameProps> = (props) => {
    const { url, isContentLoading, onScriptLoaded } = props;
    useIframeResizer(
        '#vitrinaIframe',
        {
            log: false,
            autoResize: true,
        },
        onScriptLoaded,
    );

    return (
        <iframe
            title="vitrin"
            className={cn(styles.iframe, !isContentLoading && styles.iframe_visible)}
            src={url}
            id="vitrinaIframe"
        />
    );
};

export const LoansList: FC<IProps> = (props) => {
    const { landingData, setLandingData } = props;
    const { [FORM_FIELDS.AMOUNT]: amount, [FORM_FIELDS.PERIOD]: period } = landingData || {};
    const [isScriptLoading, setIsScriptLoading] = useState(true);
    const [subFilters, setSubFilters] = useState('');
    const prevSubFilters = useRef('');
    const [frameLoadingStatus, setFrameLoadingStatus] = useState<'loading' | 'error' | 'completed' | 'clearIframe'>(
        'loading',
    );
    const [theme] = useTheme();
    const { alias } = useCurrentLocation();
    const [regionAlias, setRegionAlias] = useState(alias);
    const [isAllTypesOffers] = useExperimentGroups([
        { name: CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_MAIN_LANDING, value: ['1'] },
    ]);
    const prevTimerRef = useRef<null | number>(null);
    const placementChannel = useMemo(
        () =>
            isAllTypesOffers
                ? 'credit-selection-landing-all-types-offers'
                : 'credit-selection-landing-one-types-offers',
        [isAllTypesOffers],
    );

    const errorHandler = () => {
        if (prevTimerRef.current) {
            clearTimeout(prevTimerRef.current);
        }

        prevTimerRef.current = setTimeout(() => {
            setFrameLoadingStatus((prev) => {
                if (prev !== 'completed') {
                    return 'error';
                }
                return prev;
            });
        }, 5_000);
    };

    const iframeEventListener = (event: IframeEvent) => {
        if (event.data && event.data.eventType) {
            switch (event.data.eventType) {
                case 'FILTER_EVENT_TYPE': {
                    const data = event.data.data || {};
                    const newSubFilters = Object.entries(data)
                        .filter(([key]) => key.includes('sub-'))
                        .map(([key, value]) => `${key}=${value}`)
                        .sort()
                        .join('&');

                    if (prevSubFilters.current !== newSubFilters) {
                        setSubFilters(newSubFilters);
                        prevSubFilters.current = newSubFilters;
                    }

                    break;
                }
                case 'CHANGE_REGION': {
                    const data = event.data.data || {};
                    const { alias = 'moskva', route = '6.83.' } = (data.region as any) || {};
                    setLandingData({ [FORM_FIELDS.REGION_LOCATION_ROUTE]: route });
                    setRegionAlias(alias);
                    break;
                }
                case 'IFRAME_OFFERS_MOUNT_SUCCESS_TYPE': {
                    setFrameLoadingStatus('completed');
                    break;
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', iframeEventListener);
        return () => {
            window.removeEventListener('message', iframeEventListener);
        };
    }, []);

    const iframeUrl = useMemo(() => {
        return `${LOANS_LIST_URL}${
            regionAlias || 'moskva'
        }?theme=${theme}&amount=${amount}&period=${period}&placementChannel=${placementChannel}&${subFilters}`;
    }, [regionAlias, theme, amount, period, subFilters, placementChannel]);

    useEffect(() => {
        setFrameLoadingStatus('clearIframe');
        setTimeout(() => {
            setFrameLoadingStatus('loading');
            errorHandler();
        }, 0);
    }, [iframeUrl]);

    const showLoader = useMemo(
        () => isScriptLoading || ['clearIframe', 'loading'].includes(frameLoadingStatus),
        [isScriptLoading, frameLoadingStatus],
    );

    if (frameLoadingStatus === 'error') {
        return <div className={styles.loans_list} />;
    }

    return (
        <div className={styles.loans_list}>
            {showLoader && (
                <div className={styles.iframe_loader}>
                    <Spinner size={28} />
                </div>
            )}
            {frameLoadingStatus !== 'clearIframe' && (
                <PodborFrame
                    url={iframeUrl}
                    onScriptLoaded={() => setIsScriptLoading(false)}
                    isContentLoading={showLoader}
                />
            )}
        </div>
    );
};
