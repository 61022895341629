import { useEffect, useState } from 'react';

export const useApiScriptLoader = (url: string, apiNamespace: string) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState(false);

    useEffect(() => {
        if (apiNamespace in window) {
            return setIsScriptLoaded(true);
        }
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = async () => {
            const MAX_RETRIES = 5;
            let retries = 0;

            const checkApiNamespace = () => {
                if (apiNamespace in window) {
                    return setIsScriptLoaded(true);
                }
                if (retries < MAX_RETRIES) {
                    setTimeout(() => {
                        checkApiNamespace();
                    }, 200);
                } else {
                    return setIsScriptLoaded(true);
                }

                retries += 1;
            };

            checkApiNamespace();
        };
        script.onerror = () => {
            console.log(`Error loading script for namespace ${apiNamespace}`);
            setIsLoadingError(true);
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url, apiNamespace]);

    return { isScriptLoaded, isLoadingError };
};
