import { useCallback, useEffect } from 'react';
import { IFRAME_RESIZER_MESSAGES } from '../../constants';
import { useApiScriptLoader } from './api.script.loader';

interface ResizerContentWindowParams {
    setStaticIframeScreenSize?: boolean;
}

let IFRAME_RESIZER_CONTENT_WINDOW_INITIALIZED = false;
let IFRAME_RESIZER_INITIALIZED = false;

export const useContentWindowIframeResizer = (params?: ResizerContentWindowParams) => {
    const { isScriptLoaded: isIFrameResizerLoaded } = useApiScriptLoader(
        '/resources/selection/js/iframeresizer.contentwindow.min.js',
        'parentIFrame',
    );

    const setStaticIframeSize = () => {
        if (!window.parentIFrame) {
            return;
        }

        window.parentIFrame.autoResize(false);

        const windowOuterHeight = window.outerHeight;
        const message = {
            type: IFRAME_RESIZER_MESSAGES.STOP_AUTO_RESIZING,
            payload: JSON.stringify({ staticHeight: windowOuterHeight }),
        };

        window.parentIFrame.sendMessage(JSON.stringify(message));
    };

    useEffect(() => {
        if (isIFrameResizerLoaded && window.parentIFrame && !IFRAME_RESIZER_CONTENT_WINDOW_INITIALIZED) {
            if (params && params?.setStaticIframeScreenSize) {
                setStaticIframeSize();
            }

            IFRAME_RESIZER_CONTENT_WINDOW_INITIALIZED = true;
        }
    }, [isIFrameResizerLoaded]);
};

type IframeResizerOptions = Record<string, unknown> & {
    log?: boolean;
    bodyPadding?: string | number;
    autoResize?: boolean;
    maxWidth?: number;
    bodyMargin?: string | number;
};

export const useIframeResizer = (iframeId: string, options: IframeResizerOptions, onResizingStarted?: () => void) => {
    const { isScriptLoaded: isIFrameResizerLoaded } = useApiScriptLoader(
        '/resources/selection/js/iframeresizer.min.js',
        'iframeResize',
    );

    const enableResizing = useCallback(() => {
        if (window.iFrameResize) {
            window.iFrameResize(options, iframeId);
            if (onResizingStarted) {
                onResizingStarted();
            }
        }
    }, [options, iframeId, onResizingStarted]);

    useEffect(() => {
        if (IFRAME_RESIZER_INITIALIZED) {
            enableResizing();
        }
    }, []);

    useEffect(() => {
        if (isIFrameResizerLoaded && !IFRAME_RESIZER_INITIALIZED) {
            IFRAME_RESIZER_INITIALIZED = true;
            enableResizing();
        }
    }, [isIFrameResizerLoaded]);
};
