import { Space } from '@sravni/react-design-system';
import { FC } from 'react';
import cn from 'classnames';
import {
    LogoCarouselItemProps,
    LogoCarousel as SharedLogoCarousel,
} from '@sravni/react-shared-components/lib/LogoCarousel';
import { useIsMobile } from '@sravni/react-utils';
import { GRAY_BANKS_LOGOS } from '../../landing.common/banks.logo/constants';
import { SectionTitle } from '../components';
import { Container } from '../../landing.common';
import { HowToStep, StepsHowTo } from '../../landing.common/steps.how.to';
import commonStyles from '../../landing.common/banks.logo/styles.module.scss';
import styles from '../landing.module.scss';

const creditSteps: Array<HowToStep> = [
    {
        title: 'Заполните анкету',
        description: 'Запросим минимум данных, чтобы подобрать для вас лучшие предложения',
    },
    {
        title: 'Отправьте заявку на\u00A0Сравни',
        description: 'Покажем банки с\u00A0высокой вероятностью одобрения',
    },
    {
        title: 'Получите решение онлайн',
        description: 'Банк пришлет смс с\u00A0решением в течение 5\u00A0минут',
    },
    {
        title: 'Оформите кредит там, где вам удобно',
        description: 'Выберите отделение банка или доставку на\u00A0дом',
    },
];

export const HowToSection: FC = () => {
    const isMobile = useIsMobile();
    return (
        <>
            <Container>
                <Space size={isMobile ? 16 : 24} direction="vertical">
                    <SectionTitle title="Как подобрать кредит" className={styles['how-to-title']} />
                    <StepsHowTo steps={creditSteps} className={styles['how-to-wrapper']} />
                </Space>
            </Container>
            <SharedLogoCarousel
                className={cn(commonStyles.carousel, styles.carousel)}
                items={GRAY_BANKS_LOGOS.map(
                    ({ url, name }): LogoCarouselItemProps => ({
                        alt: name,
                        url,
                    }),
                )}
            />
        </>
    );
};
