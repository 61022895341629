import { useCallback } from 'react';
import analytics, { AnalyticEvent, Categories, EventType } from '../../helpers/analytics';

export type PushEventData = Pick<AnalyticEvent, 'eventAction'> & Partial<AnalyticEvent>;
export type ExtraEventData = Partial<PushEventData> & {
    /**
     * Если указан, то eventAction и eventLabel объединяются с базовым событием через "|"
     * Иначе событие полностью заменяет поля из базового (если указаны)
     */
    join?: true;
};
export type PushEvent = (baseEvent: PushEventData, extraEvent?: ExtraEventData) => void;

export { EventType, Categories };

type GlobalEvent = Required<Pick<AnalyticEvent, 'event' | 'eventCategory' | 'eventLabel'>> & Partial<AnalyticEvent>;

const globalEvent: GlobalEvent = {
    event: EventType.adEvent,
    eventCategory: Categories.Loan,
    eventLabel: '',
};

/**
 * Объединяет поля eventAction и eventLabel через "|"
 * Чтобы не терять обратную совместимость, добавлен флаг join,
 * но в будущем все объединение должно происходить по этому принципу
 */
const joinEvents = (baseEvent: PushEventData, extraEvent?: ExtraEventData): PushEventData => {
    const extra = extraEvent || ({} as ExtraEventData);
    return extra.join
        ? Object.assign(
              baseEvent,
              extra.eventAction ? { eventAction: [baseEvent.eventAction, extra.eventAction].join('|') } : {},
              extra.eventLabel ? { eventLabel: [baseEvent.eventLabel, extra.eventLabel].join('|') } : {},
          )
        : Object.assign(baseEvent, extra);
};

export const useAnalytics = () => {
    /** Можно указать данные по умолчанию для определенных страниц, используя setGlobalEvent  */
    const pushEvent: PushEvent = useCallback((baseEvent, extraEvent) => {
        analytics.emit({
            ...globalEvent,
            ...joinEvents(baseEvent, extraEvent),
        });
    }, []);

    /** Задает данные AnalyticEvent глобально, для pushEvent (например, eventCategory)  */
    const setGlobalEvent = useCallback((data: Partial<AnalyticEvent>) => {
        Object.assign(globalEvent, data);
    }, []);

    return { pushEvent, setGlobalEvent, globalEvent };
};
