import { Space } from '@sravni/react-design-system';
import { useCallback, useEffect } from 'react';
import { useBoolean, useIsMobile } from '@sravni/react-utils';
import cn from 'classnames';

import { Avatar } from '@sravni/react-design-system';
import { Card } from '@sravni/react-design-system';
import { Typography } from '@sravni/react-design-system';
import { Carousel } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system';
import { Portal } from '@sravni/react-design-system';

import styles from './styles.module.scss';
import { BANKS_LOGOS } from './constants';
import { sessionStorage } from '../../../helpers/storage';
import { SESSION_STORAGE_OSAGO_POPUP_KEY } from '../../../constants';
import { Dialog } from '../../dialog';

export const OsagoPopup = () => {
    const [visible, setVisible] = useBoolean(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!sessionStorage.getItem(SESSION_STORAGE_OSAGO_POPUP_KEY)) {
            setVisible.toggle();
        }
        sessionStorage.setItem(SESSION_STORAGE_OSAGO_POPUP_KEY, 'true');
    }, []);

    const avatarSize = isMobile ? 36 : 44;

    const handleOnclick = useCallback(() => {
        setVisible.toggle();
    }, []);

    return (
        <Dialog visible={visible} onClose={setVisible.toggle} fullscreen={isMobile} className={styles.popup}>
            <Dialog.Header title="Кредит с точными условиями" className={styles.header} />
            <Dialog.Content className={styles.content}>
                <Space
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    size={isMobile ? 12 : 16}
                    className={styles.cards}>
                    <Card color={'green'} className={cn(styles.card, !isMobile && styles.card__check)}>
                        <Avatar size={avatarSize} className={styles.card__avatar}>
                            1
                        </Avatar>
                        <Typography.Heading level={5} className={styles.card__header}>
                            Заполните короткую анкету
                        </Typography.Heading>
                        <Typography.Text size={12}>
                            Мы перенесли часть данных из Осаго, заполнение анкеты займет не более 5 минут
                        </Typography.Text>
                    </Card>
                    <Card color={'blue'} className={cn(styles.card, !isMobile && styles.card__percent)}>
                        <Avatar size={avatarSize} className={styles.card__avatar}>
                            2
                        </Avatar>
                        <Typography.Heading level={5} className={styles.card__header}>
                            Выберите лучшее предложение
                        </Typography.Heading>
                        <Typography.Text size={12}>
                            Покажем реальные ставки и точные условия, а не цифры из рекламы
                        </Typography.Text>
                    </Card>
                    <Card color={'orange'} className={cn(styles.card, !isMobile && styles.card__office)}>
                        <Avatar size={avatarSize} className={styles.card__avatar}>
                            3
                        </Avatar>
                        <Typography.Heading level={5} className={styles.card__header}>
                            Оформляйте там, где удобно
                        </Typography.Heading>
                        <Typography.Text size={12}>
                            Вы можете выбрать, идти в офис или сделать все онлайн
                        </Typography.Text>
                    </Card>
                </Space>

                <Carousel title={'Наши банки партнеры'} className={styles.banks}>
                    {BANKS_LOGOS.map(({ url, name }, index) => (
                        <div className={styles.bank_icon} key={name}>
                            <img src={url} key={index} alt={name} />
                        </div>
                    ))}
                    <Space direction={'horizontal'} size={12} align={'center'} className={styles.other__partners}>
                        <Avatar size={36} className={cn(styles.partners_avatar, 'h-color-O100')}>
                            +20
                        </Avatar>
                        <Typography.Text size={16} strong>
                            Партнеров
                        </Typography.Text>
                    </Space>
                </Carousel>
                <Space justify={'end'}>
                    <Button variant={'primary'} onClick={handleOnclick} className={styles.button}>
                        Продолжить
                    </Button>
                </Space>
            </Dialog.Content>
            <Portal>
                <div className={styles.backDropMobile}></div>
            </Portal>
        </Dialog>
    );
};
