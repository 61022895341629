import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Typography } from '@sravni/react-design-system';
import { CONSENT_TO_PROCESSING_PERSONAL_DATA_URL } from '../../../constants';
import cn from 'classnames';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = ComponentPropsWithoutRef<'span'> & {
    btnText?: string;
};

export const PersonalDataText: FC<Props> = ({ className, btnText = 'Заполнить анкету', ...other }) => {
    return (
        <Text className={cn(styles['personal-data-text'], className)} size={12} {...other}>
            Нажимая кнопку “{btnText}” я соглашаюсь{' '}
            <a href={CONSENT_TO_PROCESSING_PERSONAL_DATA_URL} target="_blank" rel="noopener noreferrer">
                на&nbsp;обработку персональных&nbsp;данных
            </a>
        </Text>
    );
};
