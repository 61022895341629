import { PropsWithChildren, ReactNode } from 'react';
import { Icon, Space, Avatar } from '@sravni/react-design-system';
import * as React from 'react';
import { useIsMobile } from '@sravni/react-utils';
import styles from './styles.module.scss';

interface IProps {
    icon: ReactNode;
    className?: string;
}

export const WithImage = ({ icon, children, className }: PropsWithChildren<IProps>) => {
    const isMobile = useIsMobile();

    return (
        <Space size={12} align="center" className={className}>
            <Avatar className={styles['icon-container']}>
                <Icon
                    size={20}
                    shape="round"
                    color="gray"
                    icon={icon}
                    background={!isMobile ? 'white' : 'gray'}
                    className={styles['icon-container__icon']}
                />
            </Avatar>

            {children}
        </Space>
    );
};
